<template>
  <div>
    <h2>Inbox</h2>
    <button @click="fetchEmails">Fetch New Emails</button>
    <ul>
      <li v-for="email in emails" :key="email._id">
        <div>
          <strong>From:</strong> {{ email.from }}<br />
          <strong>Subject:</strong> {{ email.subject }}<br />
          <strong>Date:</strong> {{ new Date(email.date).toLocaleString() }}<br />
          <strong>Message:</strong> {{ email.message }}<br />
          <button @click="markAsRead(email._id)" v-if="!email.isRead">Mark as Read</button>
        </div>
        <hr />
      </li>
    </ul>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      emails: []
    }
  },
  async mounted() {
    try {
      const response = await axios.get('https://63.250.47.113:2083/api/inbox')
      this.emails = response.data
    } catch (error) {
      console.error('Error retrieving emails:', error)
    }
  },
  methods: {
    async fetchEmails() {
      try {
        const response = await axios.get('https://63.250.47.113:2083/api/fetchEmails')
        console.log('Fetched emails:', response.data)
        this.refreshInbox()
      } catch (error) {
        console.error('Error fetching emails:', error)
      }
    },
    async markAsRead(id) {
      try {
        const response = await axios.post(`https://63.250.47.113:2083/api/markAsRead/${id}`)
        console.log('Email marked as read:', response.data)
        this.refreshInbox()
      } catch (error) {
        console.error('Error marking email as read:', error)
      }
    },
    async refreshInbox() {
      try {
        const response = await axios.get('https://63.250.47.113:2083/api/inbox')
        this.emails = response.data
      } catch (error) {
        console.error('Error refreshing inbox:', error)
      }
    }
  }
}
</script>
